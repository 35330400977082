import moment from 'moment'
import { PureComponent, ReactNode } from 'react'

import PlotWrapper from '@/react/visualization/PlotWrapper'
import { MountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import type { ElementMaps, TagName } from '@/types/state'
import type { PlotConfigMap, TileConfig, TileConfigMap } from '@/types/visualization'

import { ViewCompareLogic } from '../ViewCompareLogic'
import { ViewLogic } from '../ViewLogic'

type Props = {
  /**
   * PlotConfig ID
   */
  configId: string
  tileId: string
  plotConfigs: PlotConfigMap
  tileConfigs: TileConfigMap
  viewId: string
  viewOnly?: boolean
  additionalId?: string
  elementMaps: ElementMaps
  forceUpdateHandler: () => void
  filterControlVariables: string[]
  canCompareCasters: boolean
  selectedComparisonCaseIds: string[]
  referenceDate: Date
  plotsCompareCasterInformation: Record<string, Partial<ElementMaps>>
  filteredElementCache: Record<string, string[]>
  setTileWarnings: (tileWarnings: Record<string, Record<string, any>>) => void
  caseIds: string[]
  mountLogToKeyUUIDsMap: MountLogToKeyUUIDsMap
  casterDataServer: CasterDataServerState
  temporalData: TemporalDataState
}

export class MergedDynamicPlot extends PureComponent<Props> {
  private readonly getXDomain = (
    xValues: number[],
    tileData: TileConfig,
    dynamicDataList: any[],
  ): Domain => {
    const {
      xDomainMax: xMax,
      xDomainMin: xMin,
      xAxisPadding = 0,
      followPasslnCoord,
    } = tileData

    const minDefined = xMin !== undefined && String(xMin).length > 0 && !followPasslnCoord
    const maxDefined = xMax !== undefined && String(xMax).length > 0 && !followPasslnCoord

    const xDomain: Domain = [
      minDefined ? Number(xMin) : xValues[0],
      maxDefined ? Number(xMax) : xValues[xValues.length - 1],
    ]

    xDomain.sort()

    const realXDomain = ViewLogic.getRealXDomainForMergedPlot(xDomain, dynamicDataList, tileData)

    realXDomain.sort((a, b) => a - b)

    realXDomain[0] = realXDomain[0] - (minDefined ? 0 : Number(xAxisPadding))
    realXDomain[1] = realXDomain[1] + (maxDefined ? 0 : Number(xAxisPadding))

    return realXDomain
  }

  private readonly getYDomain = (
    yValues: number[],
    tileData: TileConfig,
    dynamicDataList: any[],
    xDomain: Domain,
  ): Domain => {
    const {
      yDomainMax: yMax,
      yDomainMin: yMin,
      yAxisPadding = 0,
    } = tileData

    const minDefined = yMin !== undefined && String(yMin).length > 0
    const maxDefined = yMax !== undefined && String(yMax).length > 0

    const maxYValues = yValues[yValues.length - 1]

    const yDomain: Domain = [
      minDefined ? Number(yMin) : yValues[0],
      maxDefined ? Number(yMax) : maxYValues,
    ]

    yDomain.sort((a, b) => a - b)

    const realYDomain = ViewLogic.getRealYDomainForMergedPlot(xDomain, dynamicDataList, tileData, yDomain)

    realYDomain[0] = minDefined ? Number(yMin) : realYDomain[0]
    realYDomain[1] = maxDefined ? Number(yMax) : realYDomain[1]

    realYDomain.sort((a, b) => a - b)

    realYDomain[0] = realYDomain[0] - (minDefined ? 0 : Number(yAxisPadding))
    realYDomain[1] = realYDomain[1] + (maxDefined ? 0 : Number(yAxisPadding))

    return realYDomain
  }

  public override render (): ReactNode | null {
    const {
      configId: plotConfigId,
      tileId,
      plotConfigs,
      tileConfigs,
      viewId,
      elementMaps,
      filterControlVariables,
      canCompareCasters,
      selectedComparisonCaseIds,
      plotsCompareCasterInformation,
      filteredElementCache,
      forceUpdateHandler,
      caseIds,
      mountLogToKeyUUIDsMap,
      casterDataServer,
      temporalData,
      viewOnly,
      additionalId,
    } = this.props

    const tileData = tileConfigs[tileId]
    const plotConfig = plotConfigs[plotConfigId]
    const tileWarnings: Record<string, Record<string, any>> = {}

    if (!plotConfig || !tileData) {
      return null
    }

    const dynamicDataList: any[] = []
    let xValues: number[] = []
    let yValues: number[] = []

    const hasFilter = ViewLogic.isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) ||
      ViewLogic.isMergedDynamicDataSourceWithFilterControlVariableInFilter(plotConfig, filterControlVariables)

    const xLabels: string[] = []
    const yLabels: string[] = []

    // did not use plotConfig.configs because in plotly configIds is used and configs sometimes has another order
    plotConfig.configIds?.forEach((plotConfigId: string) => {
      const config = plotConfigs[plotConfigId]

      if (!config) {
        return null
      }

      const [ type, attrY ] = config.selectedY.split('|') as [ TagName, string ]
      const [ , attrX ] = config.selectedX.split('|') as [ TagName, string ]

      const comparisonCasterData: any[] = []

      if (canCompareCasters && selectedComparisonCaseIds?.length) {
        for (const caseId of selectedComparisonCaseIds) {
          const data: any = {}

          if (caseId.includes('cds_timestamp')) {
            data.timestamp = caseId.split('timestamp_')[1]
            data.casterName = moment(new Date(Number(data.timestamp))).format('DD/MM/YYYY HH:mm:ss')
            comparisonCasterData.push(data)

            continue
          }

          data.elementMaps = plotsCompareCasterInformation[caseId]

          if (!data.elementMaps) {
            continue
          }

          const isTimestampCaseId = caseId.includes('_')
          const realCaseId = isTimestampCaseId ? caseId.split('_')[0] : caseId
          const casterIndex = caseIds.indexOf(realCaseId)
          const timestamp = isTimestampCaseId ? caseId.split('_')[1] : undefined

          data.caseId = caseId
          data.casterName = !isTimestampCaseId
            ? `C${casterIndex + 1}`
            : `C${casterIndex + 1} - ${moment(new Date(Number(timestamp))).format('DD/MM/YYYY HH:mm:ss')}`
          comparisonCasterData.push(data)
        }
      }

      xLabels.push(attrX)
      yLabels.push(attrY)

      const elements = ViewLogic
        .getDynamicElementsFromConfig(elementMaps, config, filteredElementCache, type)

      const dynamicData: any = []
      const isRefIdLine = config.filter?.includes('#ref=')

      if (type === 'DataLine') {
        const filterEmpty = !config.filter

        dynamicData[0] = ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
          elements,
          elementMaps,
          type,
          attrX,
          attrY,
          filterEmpty,
          isRefIdLine,
        )

        if (isRefIdLine) {
          for (const point of dynamicData[0]) {
            xValues.push(point.x)
            yValues.push(point.y)
          }
        }
        else {
          for (const line of dynamicData[0]) {
            for (const point of line) {
              xValues.push(point.x)
              yValues.push(point.y)
            }
          }
        }

        for (let i = 0; i < comparisonCasterData.length; i++) {
          const compareData = comparisonCasterData[i]
          const datalinePoints: any = ViewCompareLogic.getPointsFromComparisonDataLine(
            compareData.elementMaps,
            attrX,
            attrY,
            compareData.caseId,
            plotConfig,
            config.filter?.includes('#ref='),
          )

          if (!datalinePoints.length) {
            if (!tileWarnings[plotConfigId]) {
              tileWarnings[plotConfigId] = {}
            }

            if (!tileWarnings[plotConfigId][compareData.casterName]) {
              tileWarnings[plotConfigId][compareData.casterName] = true
            }
          }

          datalinePoints.forEach?.((line: any) => {
            if (line && !(line instanceof Array)) {
              line.casterName = compareData.casterName
            }

            line.forEach?.((point: any) => {
              point.casterName = compareData.casterName
            })
          })

          dynamicData.push(datalinePoints)

          if (isRefIdLine) {
            for (const point of datalinePoints) {
              xValues.push(point.x)
              yValues.push(point.y)
            }
          }
          else {
            for (const line of datalinePoints) {
              for (const point of line) {
                xValues.push(point.x)
                yValues.push(point.y)
              }
            }
          }
        }
      }
      else if (attrX === 'dataOverTime' && config.filter) {
        const realDataUUID = config.filter.split('realDataUUID=')[1]

        dynamicData[0] = []

        if (realDataUUID && temporalData[realDataUUID]) {
          dynamicData[0] = temporalData[realDataUUID].map((mountLog: any) => ({
            x: new Date(mountLog.mountedAt),
            y: mountLog[attrY] ?? mountLog?.additionalData?.[attrY],
          }))
        }

        for (const point of dynamicData[0]) {
          xValues.push(point.x)
          yValues.push(point.y)
        }
      }
      else {
        dynamicData[0] = ViewLogic
          .getDynamicDataFromElements(elements, elementMaps, type, attrX, attrY)

        for (let i = 0; i < comparisonCasterData.length; i++) {
          const data = comparisonCasterData[i]

          let points: Array<{ x: number, y: number }> = []

          if (data.timestamp) {
            const timestamp = data.timestamp

            points = ViewCompareLogic.getPointsFromDataServerData(
              type,
              elements,
              elementMaps,
              attrX,
              attrY,
              timestamp,
              mountLogToKeyUUIDsMap,
              casterDataServer,
            )
          }
          else {
            points = ViewCompareLogic.getPointsFromComparisonCaster(
              data.elementMaps,
              type,
              attrX,
              attrY,
              config,
              data.caseId,
            )
          }

          dynamicData[i + 1] = points

          if (!points.length) {
            if (!tileWarnings[plotConfigId]) {
              tileWarnings[plotConfigId] = {}
            }

            if (!tileWarnings[plotConfigId][data.casterName]) {
              tileWarnings[plotConfigId][data.casterName] = true
            }
          }

          for (const el of dynamicData[i + 1]) {
            el.casterName = data.casterName
          }
        }

        for (const line of dynamicData) {
          for (const el of line) {
            xValues.push(el.x)
            yValues.push(el.y)
          }
        }
      }

      if (!dynamicData.length) {
        return null
      }

      if (dynamicData.every((line: any) => !line.length)) {
        dynamicDataList.push([ [ { x: -Infinity, y: -Infinity } ] ])

        return
      }

      dynamicDataList.push(dynamicData)
    })

    let hasNoData = false

    if (!xValues.length || !yValues.length) {
      xValues.push(0, 10)
      yValues.push(0, 10)

      hasNoData = true
    }

    if (!dynamicDataList.length && hasFilter) {
      plotConfig.configs?.forEach(() => {
        // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
        dynamicDataList.push([ [ { x: -Infinity, y: -Infinity } ] ])
        xValues.push(0)
        yValues.push(0)
      })

      hasNoData = true
    }
    else if (
      hasFilter &&
      dynamicDataList.every((
        dynamicData: any[],
      ) => (Array.isArray(dynamicData) && dynamicData.every((line: any) => !line.length)))
    ) {
      plotConfig.configs?.forEach(() => {
        // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
        // TODO: fix, if we push this fallback data, data is wrong
        // if (dynamicDataList[0]?.length === 1) {
        // dynamicDataList[0][0].push({ x: 0, y: 0 })
        // }

        xValues.push(0)
        yValues.push(0)
      })

      hasNoData = true
    }

    xValues = xValues.sort((a, b) => a - b)
    yValues = yValues.sort((a, b) => a - b)

    let shapeIds: string[] = []
    const shapeData: any = []

    if (tileData.type === 'line' || tileData.type === 'area' || tileData.type === 'bar') {
      shapeIds = (tileData.shapeIds ?? [])
        .map(shapeId => shapeId.id)
        .filter(shapeId => shapeId !== undefined && shapeId !== '')

      for (const shape of shapeIds) {
        const config = plotConfigs[shape]

        if (
          shape === undefined ||
          shape === '' ||
          shape === 'passlineCoord' ||
          filterControlVariables.includes(shape) ||
          !config
        ) {
          continue
        }

        const { selectedY } = config
        const [ elementType, shapeAttrY ] = selectedY.split('|')
        const elements = ViewLogic.getDynamicElementsFromConfig(elementMaps, config, {}, elementType)

        shapeData.push({
          shapeId: shape,
          data: ViewLogic.getShapeDynamicData(elements, elementMaps, shapeAttrY),
        })
      }
    }

    const maxYValues = yValues[yValues.length - 1]

    const xDomain = this.getXDomain(xValues, tileData, dynamicDataList)
    const yDomain = this.getYDomain(yValues, tileData, dynamicDataList, xDomain)

    return (
      <PlotWrapper
        tileId={tileId}
        key={tileId}
        configId={plotConfigId}
        dynamicDataList={dynamicDataList}
        type={tileData.type}
        shapeIds={shapeIds}
        xRange={[ xValues[0], xValues[xValues.length - 1] ]}
        yValueRange={[ yValues[0], maxYValues ]}
        valueRange={[ yValues[0], maxYValues ]}
        xValues={xValues}
        xDomain={xDomain}
        yDomain={yDomain}
        xLabel={tileData.xLabel ?? Array.from(new Set(xLabels)).join(', ') ?? ''}
        yLabel={tileData.yLabel ?? Array.from(new Set(yLabels)).join(', ') ?? ''}
        flipAxes={plotConfig.flipAxes}
        isMergedDynamicData
        hasNoData={hasNoData}
        shapeData={shapeData}
        viewId={viewId}
        forceUpdateHandler={forceUpdateHandler}
        viewOnly={viewOnly}
        additionalId={additionalId}
      />
    )
  }
}
