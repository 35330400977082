import styled, { css } from 'styled-components'

import { ResizeDetector } from '@/react/ResizeDetector'

export const Wrapper = styled.div`${({ theme }) =>
  css`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${theme.colors.swatch22};
  color: ${theme.colors.swatch9};
  border-radius: 16px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
`}`

export const TopBar = styled.div<{ $canMove?: boolean, $closed?: boolean }>`${({ theme, $canMove, $closed }) =>
  css`
  height: 35px;
  width: 100%;
  cursor: ${$canMove ? 'move' : 'default'};
  z-index: ${$closed ? '-1' : '2'};
  background-color: ${theme.colors.swatch4};
  border-radius: 14px 14px 0 0;
`}`

export const Container = styled(ResizeDetector)`${() =>
  css`
  position: initial;
  overflow: hidden;
  height: calc(100% - 35px);
  width: 100%;
  user-select: none;
  z-index: 0;
`}`

export const PlotTitle = styled.div<{ $closed?: boolean }>`${({ theme, $closed }) =>
  css`
  display: flex;
  justify-content: space-between;
  left: 0;
  top: 0;
  color: ${theme.colors.swatch8};
  padding: 0 8px;
  line-height: 34px;
  font-size: 16px;
  user-select: none;
  z-index: ${$closed ? '-1' : '1'};
`}`

export const Pipe = styled.div`${({ theme }) =>
  css`
  display: inline-block;
  color: ${theme.colors.swatch6};
  border-left: solid 1px ${theme.colors.swatch6};
  height: 25px;
  vertical-align: sub;
  margin: 0 5px;
`}`

export const Name = styled.span<{ $isEditModeOn?: boolean }>`${({ theme, $isEditModeOn }) =>
  css`
  color: ${theme.colors.swatch8};
  width: calc(100% - ${$isEditModeOn ? 122 : 32}px); //TODO: calculate margin based on active buttons
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`}`

export const Menu = styled.div`${({ theme }) =>
  css`
  position: absolute;
  display: flex;
  right: 0;
  padding: 0 8px;
  line-height: 40px;
  top: 4px;
  align-items: flex-end;
  color: ${theme.colors.swatch8};
  z-index: 1;

  svg {
    font-size: 20px;
  }

  i {
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
  }

  i:hover {
    color: ${theme.colors.swatch9};
  }

  i:focus {
    color: ${theme.colors.swatch9};
  }
`}`
