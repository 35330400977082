/* eslint-env browser */
import { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { setVisualizationConfig } from '@/api/visualization-config'
import FeatureFlags from '@/react/FeatureFlags'
import { AppState } from '@/store/application/main/consts'
import { getElementMapsObject } from '@/store/elements/logic'
import * as TileWarningsActions from '@/store/tileWarnings'
import { getReferenceDate } from '@/store/timestamps'
import * as VisualizationActions from '@/store/visualization/actions'
import { DefaultState } from '@/types/state'
import { TileConfig } from '@/types/visualization'

import { DynamicPlot } from './Plots/DynamicPlot'
import { MergedDynamicPlot } from './Plots/MergedDynamicPlot'
import { StaticPlot } from './Plots/StaticPlot'
import TablePlot from './Plots/TablePlot/TablePlot'
import PlotWrapper from '../PlotWrapper'

const connector = connect(({
  application,
  ComparisonCasters,
  filter,
  visualization,
  timestamps,
  mountLogToKeyUUIDsMap,
  casterDataServer,
  temporalData,
  ...remainingState
}: DefaultState) => ({
  comparisonCasters: ComparisonCasters,
  currentDashboard: visualization.currentDashboard,
  currentProject: application.main.currentProject,
  currentProjectCasesMetadata: application.main.currentProjectCasesMetadata,
  featureFlags: FeatureFlags.getRealFeatureFlags({ application } as DefaultState),
  filterControlVariables: filter.filterControlVariables,
  plotConfigs: visualization.plotConfigs,
  tileConfigs: visualization.tileConfigs,
  viewsObject: visualization.viewsObject,
  selectedComparisonCaseIds: visualization.selectedComparisonCaseIds,
  plotsCompareCasterInformation: visualization.plotsCompareCasterInformation,
  casterDataServer,
  mountLogToKeyUUIDsMap,
  temporalData,
  timestamps,
  visualizationMetaInformation: visualization.visualizationMetaInformation,
  ...getElementMapsObject(remainingState as DefaultState),
}), {
  setTileWarnings: TileWarningsActions.setTileWarnings,
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  tileId: string
  viewId: string
  viewOnly?: boolean
  additionalId?: string
}

export class ViewTile extends PureComponent<Props> {
  private readonly forceUpdateHandler = () => {
    requestAnimationFrame(() => requestAnimationFrame(() => this.forceUpdate()))
  }

  private readonly handleVisualizationConfigUpdate = (tileConfig: TileConfig) => {
    const { plotConfigs, tileConfigs, viewsObject, tileId, visualizationMetaInformation } = this.props
    const configId = visualizationMetaInformation?.[AppState.Caster]?.config

    if (!configId) {
      return
    }

    const updatedTileConfigs = {
      ...tileConfigs,
      [tileId]: {
        ...tileConfigs[tileId],
        ...tileConfig,
      },
    }

    setVisualizationConfig(configId, { plotConfigs, tileConfigs: updatedTileConfigs, viewsObject })
  }

  private readonly getViewTile = () => {
    const {
      tileId,
      plotConfigs,
      viewId,
      viewOnly,
      additionalId,
      tileConfigs,
      setTileWarnings,
      filterControlVariables,
      Caster,
      timestamps,
      featureFlags,
      selectedComparisonCaseIds,
      plotsCompareCasterInformation,
      currentProjectCasesMetadata,
      mountLogToKeyUUIDsMap,
      temporalData,
      saveTileConfig,
    } = this.props

    const elementMaps = getElementMapsObject(this.props)

    const referenceDate = getReferenceDate(timestamps)
    const canCompareCasters = FeatureFlags.canViewCasterComparison(featureFlags)

    if (!Caster || !tileConfigs) {
      return null
    }

    const filteredElementCache: Record<string, string[]> = {}
    const { configId, type, isDynamicData, isMergedDynamicData } = tileConfigs?.[tileId] ?? {}

    if (configId !== undefined && plotConfigs && plotConfigs[configId] && !isDynamicData && !isMergedDynamicData) {
      return (
        <StaticPlot
          configId={configId}
          tileId={tileId}
          plotConfigs={plotConfigs}
          tileConfigs={tileConfigs}
          viewId={viewId}
          forceUpdateHandler={this.forceUpdateHandler}
        />
      )
    }
    else if (configId !== undefined && !/^config_/.test(configId) && !isDynamicData && !isMergedDynamicData) {
      return (
        <PlotWrapper
          tileId={tileId}
          key={tileId}
          configId={configId}
          type={type}
          viewId={viewId}
          forceUpdateHandler={this.forceUpdateHandler}
        />
      )
    }
    else if (type === 'table') {
      return (
        <TablePlot
          plotConfig={plotConfigs[configId]}
          tileId={tileId}
          configId={configId}
          viewId={viewId}
          temporalData={temporalData}
          tileConfig={tileConfigs[tileId]}
          saveTileConfig={saveTileConfig}
          onVisualizationConfigUpdate={this.handleVisualizationConfigUpdate}
        />
      )
    }
    else if (isDynamicData && configId !== undefined) {
      return (
        <DynamicPlot
          caseIds={currentProjectCasesMetadata.map(({ id }) => id)}
          canCompareCasters={canCompareCasters}
          configId={configId}
          elementMaps={elementMaps}
          filteredElementCache={filteredElementCache}
          plotConfigs={plotConfigs}
          plotsCompareCasterInformation={plotsCompareCasterInformation}
          referenceDate={referenceDate}
          selectedComparisonCaseIds={selectedComparisonCaseIds}
          tileId={tileId}
          viewId={viewId}
          setTileWarnings={setTileWarnings}
          filterControlVariables={filterControlVariables}
          forceUpdateHandler={this.forceUpdateHandler}
          tileConfigs={tileConfigs}
          mountLogToKeyUUIDsMap={mountLogToKeyUUIDsMap}
          temporalData={temporalData}
          casterDataServer={this.props.casterDataServer}
        />
      )
    }
    else if (isMergedDynamicData && configId !== undefined) {
      return (
        <MergedDynamicPlot
          caseIds={currentProjectCasesMetadata.map(({ id }) => id)}
          canCompareCasters={canCompareCasters}
          configId={configId}
          elementMaps={elementMaps}
          filteredElementCache={filteredElementCache}
          plotConfigs={plotConfigs}
          plotsCompareCasterInformation={plotsCompareCasterInformation}
          referenceDate={referenceDate}
          selectedComparisonCaseIds={selectedComparisonCaseIds}
          tileId={tileId}
          viewId={viewId}
          setTileWarnings={setTileWarnings}
          filterControlVariables={filterControlVariables}
          forceUpdateHandler={this.forceUpdateHandler}
          tileConfigs={tileConfigs}
          mountLogToKeyUUIDsMap={mountLogToKeyUUIDsMap}
          casterDataServer={this.props.casterDataServer}
          temporalData={temporalData}
          viewOnly={viewOnly}
          additionalId={additionalId}
        />
      )
    }
  }

  public override render () {
    return this.getViewTile()
  }
}

export default compose<any>(connector)(ViewTile)
