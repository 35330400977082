import Plotly from 'plotly.js-dist-min'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import BaseDialog from '@/react/dialogs/BaseDialog'
import { type ResizeData, ResizeDetector } from '@/react/ResizeDetector'
import Input from '@/react/specific/Input'
import * as ApplicationActions from '@/store/application/main/actions'
import { updateElement } from '@/store/elements/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import { DefaultState } from '@/types/state'
import { Translation } from '@/types/translation'
import type { PlotConfig } from '@/types/visualization'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { EffectStyle } from '../../PlotWrapper/PlotlyWrapper/editable/EditableBase'
import Line from '../../PlotWrapper/PlotlyWrapper/editable/Line'
import ViewTile from '../ViewTile'

const showActions = false // currently disabled
const actionsHeight = showActions ? '75px' : 0

const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: ${actionsHeight};
  padding: 10px 40px 0 50px;
  display: flex;
  justify-content: space-between;
` 

const Container = styled(ResizeDetector)`
  position: absolute;
  top: ${actionsHeight};
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 10px;

  // since we have disabled zooming, we need to use the default cursor
  .js-plotly-plot .plotly .cursor-pointer {
    cursor: default;
  }

  // mute traces
  .trace.scatter .lines path.js-line:not(.editable-update) {
    opacity: .33 !important;
  }

  // mute current passline line
  g.layer-above > g.shapelayer > path {
    stroke-opacity: .33 !important;
  }

  // drag circle styles
  .edit-circle {
    cursor: move;
    fill: #fff;
  }
`

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  params: state.application.main.params,
  currentSimulationCase: state.application.main.currentSimulationCase,
  elementMaps: getElementMapsObject(state),
}), {
  closeDialog: ApplicationActions.closeDialog,
  updateElement,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}

type State = {
  plotConfig: PlotConfig | null
  effectPointsToLeft: number
  effectPointsToRight: number
  effectStyleLeft: EffectStyle
  effectStyleRight: EffectStyle
}

export class EditCriticalStrainCurveDialog extends Component<Props, State> {
  @Identifiable('EditCriticalStrainCurveDialog') public static readonly NAME: string

  private readonly line = new Line()

  private readonly additionalId = uuid()

  public override state: State = {
    plotConfig: null,
    effectPointsToLeft: 0,
    effectPointsToRight: 0,
    effectStyleLeft: 'divide2',
    effectStyleRight: 'divide2',
  }

  public override componentDidMount () {
    const { elementMaps, plotConfigs, params, updateElement } = this.props
    const plotConfig = plotConfigs[params.plotConfigId]

    this.setState({ plotConfig })

    this.line.setData(plotConfig, elementMaps, updateElement)
  }

  public override componentDidUpdate () {
    const { elementMaps, plotConfigs, params, updateElement } = this.props
    const plotConfig = plotConfigs[params.plotConfigId]

    this.line.setData(plotConfig, elementMaps, updateElement)
    this.line.setEditData(this.getEditData())

    requestAnimationFrame(() => {
      this.drawEditableLine()
    })
  }

  private readonly handleClose = () => {
    this.props.closeDialog(EditCriticalStrainCurveDialog.NAME)
  }

  private readonly handleResize = ({ width, height }: ResizeData) => {
    const { params: { tileId } } = this.props
    const plot: any = document.getElementById(`plot_${tileId}_${this.additionalId}`)

    if (!plot?.layout) {
      return
    }

    requestAnimationFrame(() => {
      try {
        Plotly.relayout(plot, { ...plot.layout, width, height })

        requestAnimationFrame(() => {
          this.drawEditableLine()
        })
      }
      catch (e) {}
    })
  }

  private readonly getEditData = () => {
    const { effectPointsToLeft, effectPointsToRight, effectStyleLeft, effectStyleRight } = this.state

    return { effectPointsToLeft, effectPointsToRight, effectStyleLeft, effectStyleRight }
  }

  private readonly drawEditableLine = () => {
    const { params: { tileId }, currentSimulationCase } = this.props
    const plot: any = document.getElementById(`plot_${tileId}_${this.additionalId}`)

    if (!plot?.layout || !plot?.data || !currentSimulationCase) {
      return
    }

    const data = plot.data
      .filter((trace: any) => trace.text.some((line: string) => line.toLowerCase().includes('critical')))

    const { xaxis: { range: xDomain }, yaxis: { range: yDomain } } = plot.layout

    this.line.draw(
      plot, 
      data, 
      xDomain, 
      yDomain, 
      false, 
      this.getEditData(),
      currentSimulationCase.id,
      currentSimulationCase.currentCasterId!,
    )
  }

  public override render () {
    const { params } = this.props

    const effectStyleSelector = [ 'divide2', 'linear', 'divide4', 'no-divide' ].map((entry, index) => ({
      key: entry,
      value: <span>Option {index + 1}</span>,
    } as Selector)) ?? []

    const { effectPointsToLeft, effectPointsToRight, effectStyleLeft, effectStyleRight } = this.state

    return (
      <BaseDialog
        title='Critical Strain Curve Editor'
        icon='pe-7s-graph1'
        header='Critical Strain Curve Editor'
        onClose={this.handleClose}
        contentMinHeight='calc(90vh - 100px)'
        fullWidth
      >
        {
          showActions && (
            <Actions>
              <Input
                label='Effect Style Left'
                type='select'
                name='effectStyleLeft'
                value={effectStyleLeft}
                selectors={effectStyleSelector}
                onChange={event => this.setState({ effectStyleLeft: event.target.value })}
                noMarginTop
              />
              <Input
                label='Effect Points to Left'
                type='number'
                name='effectPointsToLeft'
                value={effectPointsToLeft}
                min={0}
                max={100}
                step={1}
                onChange={event => this.setState({ effectPointsToLeft: Number(event.target.value) })}
                noMarginTop
              />
              <Input
                label='Effect Points to Right'
                type='number'
                name='effectPointsToRight'
                value={effectPointsToRight}
                min={0}
                max={100}
                step={1}
                onChange={event => this.setState({ effectPointsToRight: Number(event.target.value) })}
                noMarginTop
              />
              <Input
                label='Effect Style Right'
                type='select'
                name='effectStyleRight'
                value={effectStyleRight}
                selectors={effectStyleSelector}
                onChange={event => this.setState({ effectStyleRight: event.target.value })}
                noMarginTop
              />
            </Actions>
          )
        }
        <Container onResize={(data) => this.handleResize(data)}>
          <ViewTile tileId={params.tileId} additionalId={this.additionalId} viewId={null} viewOnly />
        </Container>
      </BaseDialog>
    )
  }
}

export default compose<any>(connector)(EditCriticalStrainCurveDialog)
